import { LanguageStruct } from "./Language";

export const en_GB = new LanguageStruct("8ccf9a09-2b3b-11eb-bfa0-0271269a839f", "en-GB", "en", "en", "English");
export const fr_FR = new LanguageStruct("b31bb4da-2b3b-11eb-bfa0-0271269a839f", "fr-FR", "fr", "fr", "French");
export const it_IT = new LanguageStruct("b31bb391-2b3b-11eb-bfa0-0271269a839f", "it-IT", "it", "it", "Italian");
export const de_DE = new LanguageStruct("b31bb1e6-2b3b-11eb-bfa0-0271269a839f", "de-DE", "de", "de", "German");
export const es_ES = new LanguageStruct("b31bafd3-2b3b-11eb-bfa0-0271269a839f", "es-ES", "es", "es", "Spanish");
export const ar_AE = new LanguageStruct("7d77cbfd-2b3d-11eb-bfa0-0271269a839f", "ar-AE", "ar", "ar", "Arabic (U.A.E.)");
export const pt_PT = new LanguageStruct("8d5d5e95-2b3d-11eb-bfa0-0271269a839f", "pt-PT", "pt", "pt", "Portuguese");
export const nl_NL = new LanguageStruct("b3ad2209-2b3b-11eb-bfa0-0271269a839f", "nl-NL", "nl", "nl", "Dutch");
export const tl_TL = new LanguageStruct("07198b9a-2d6f-11eb-a710-0a4cd430c505", "tl-TL", "tl", "tl", "Tagalog");
export const he_IL = new LanguageStruct("0eb8b9bf-2b3e-11eb-bfa0-0271269a839f", "he-IL", "he", "he", "Hebrew");
export const bs_Latn = new LanguageStruct("1d0bd14c-614a-46f6-8a7c-2c89fa0a1f55", "bs-Latn", "bs", "bs", "Bosnian"); // prettier-ignore
export const hr_HR = new LanguageStruct("2f54edb6-2b3c-11eb-bfa0-0271269a839f", "hr-HR", "hr", "hr", "Croatian");
export const id_ID = new LanguageStruct("482a5c47-0f47-4145-a8f1-02841b859465", "id-ID", "id", "id", "Indonesian");
export const cs_CZ = new LanguageStruct("56f1ce9f-2b3c-11eb-bfa0-0271269a839f", "cs-CZ", "cs", "cs", "Czech");
export const ta_IN = new LanguageStruct("769a214a-2b3f-11eb-bfa0-0271269a839f", "ta-IN", "ta", "ta", "Tamil");
export const bg_BG = new LanguageStruct("7d77cdb5-2b3d-11eb-bfa0-0271269a839f", "bg-BG", "bg", "bg", "Bulgarian");
export const da_DK = new LanguageStruct("7d77d9cc-2b3d-11eb-bfa0-0271269a839f", "da-DK", "da", "da", "Danish");
export const ja_JP = new LanguageStruct("8d5d6680-2b3d-11eb-bfa0-0271269a839f", "ja-JP", "ja", "ja", "Japanese");
export const sv_SE = new LanguageStruct("96dd75b1-2b3d-11eb-bfa0-0271269a839f", "sv-SE", "sv", "sv", "Swedish");
export const zh_CN = new LanguageStruct("9824964d-2b3c-11eb-bfa0-0271269a839f", "zh-CN", "zh", "zh", "Chinese");
export const pl_PL = new LanguageStruct("9d2a1f9d-2b3d-11eb-bfa0-0271269a839f", "pl-PL", "pl", "pl", "Polish");
export const no_NO = new LanguageStruct("9f8b12c8-2b3d-11eb-bfa0-0271269a839f", "no-NO", "no", "no", "Norwegian");
export const nb_NO = new LanguageStruct(
    "3e373345-c16e-46e8-8bbd-ccbe6f94b59d",
    "nb-NO",
    "nb",
    "nb",
    "Norwegian Bokmål"
);
export const nn_NO = new LanguageStruct(
    "e3992e8a-6b1b-4423-a311-0d6c75006f85",
    "nn-NO",
    "nn",
    "nn",
    "Norwegian Nynorsk"
);
export const hu_HU = new LanguageStruct("a30a27ae-2b3e-11eb-bfa0-0271269a839f", "hu-HU", "hu", "hu", "Hungarian");
export const is_IS = new LanguageStruct("a30a295a-2b3e-11eb-bfa0-0271269a839f", "is-IS", "is", "is", "Icelandic");
export const lt_LT = new LanguageStruct("a30a37a8-2b3e-11eb-bfa0-0271269a839f", "lt-LT", "lt", "lt", "Lithuanian");
export const sk_SK = new LanguageStruct("a30a4c8c-2b3e-11eb-bfa0-0271269a839f", "sk-SK", "sk", "sk", "Slovak");
export const th_TH = new LanguageStruct("a30a57c6-2b3e-11eb-bfa0-0271269a839f", "th-TH", "th", "th", "Thai");
export const tr_TR = new LanguageStruct("a30a5932-2b3e-11eb-bfa0-0271269a839f", "tr-TR", "tr", "tr", "Turkish");
export const ru_RU = new LanguageStruct("a86d73d8-2b3b-11eb-bfa0-0271269a839f", "ru-RU", "ru", "ru", "Russian");
export const ko_KR = new LanguageStruct("ad1817cf-2b3d-11eb-bfa0-0271269a839f", "ko-KR", "ko", "ko", "Korean");
export const ca_ES = new LanguageStruct("ad18194c-2b3d-11eb-bfa0-0271269a839f", "ca-ES", "ca", "ca", "Catalan");
export const hi_IN = new LanguageStruct("c7092f4e-454f-4482-815a-b30f13133867", "hi-IN", "hi", "hi", "Hindi");
export const fi_FI = new LanguageStruct("f233fc65-2b3c-11eb-bfa0-0271269a839f", "fi-FI", "fi", "fi", "Finnish");

export const DEFAULT_LANGUAGE = en_GB;

export const LANGUAGES = [
    en_GB,
    fr_FR,
    it_IT,
    de_DE,
    es_ES,
    ar_AE,
    pt_PT,
    nl_NL,
    tl_TL,
    he_IL,
    bs_Latn,
    hr_HR,
    id_ID,
    cs_CZ,
    ta_IN,
    bg_BG,
    da_DK,
    ja_JP,
    sv_SE,
    zh_CN,
    pl_PL,
    no_NO,
    nb_NO,
    nn_NO,
    hu_HU,
    is_IS,
    lt_LT,
    sk_SK,
    th_TH,
    tr_TR,
    ru_RU,
    ko_KR,
    ca_ES,
    hi_IN,
    fi_FI,
] as const satisfies LanguageStruct[];

export type LanguageType = (typeof LANGUAGES)[number];

type MapToPropertyTuple<T extends readonly unknown[], P extends keyof T[number]> = {
    [K in keyof T]: T[K][P];
};
export const LANGUAGE_ID_LIST = LANGUAGES.map((language) => language.id) as MapToPropertyTuple<typeof LANGUAGES, "id">;
