import { AssetOptions } from "./AssetOptions";
import { AssetType } from "./AssetType";
import { AssetUrl } from "./AssetUrl";
import { Key } from "./Key";
import { VaultUrl } from "./VaultUrl";
import { Edits } from "./types";
import { getDomain } from "./utils/getDomain";

export type AssetToUrlFactory = (asset: AssetUrl) => VaultUrl | URL;
export type AssetToVaultUrlFactory = (asset: AssetUrl) => VaultUrl;

const vaultFileUrlFactory = composeImageTransformations((asset) => {
    return new VaultUrl({
        key: new Key("vaultFiles", asset.id),
        bucket: "tech.holibob.vault",
    });
});

const URL_PRODUCER: Record<AssetType, AssetToUrlFactory | undefined> = {
    hbmlImage: vaultFileUrlFactory,
    distributionChannelPageImage: vaultFileUrlFactory,
    distributionChannelAuthorAvatarImage: vaultFileUrlFactory,
    distributionChannelPageCategoryImage: vaultFileUrlFactory,
    hierarchyCurationMetaImage: vaultFileUrlFactory,
    hbmlSectionImage: vaultFileUrlFactory,
    heroImage: vaultFileUrlFactory,
    hbmlStackItemImage: vaultFileUrlFactory,
    brandVoucherLogo: vaultFileUrlFactory,
    brandLogo: vaultFileUrlFactory,
    avatar: vaultFileUrlFactory,
    organizationBannerImage: vaultFileUrlFactory,
    organizationLogo: vaultFileUrlFactory,
    heroVideo(asset) {
        return new URL(`https://${getDomain("transformless")}/herovideo/${asset.id}`);
    },
    productImage: composeImageTransformations((asset) => {
        return new VaultUrl({
            key: new Key("productImages", asset.id),
        });
    }),
    productReviewImage: composeImageTransformations((asset) => {
        return new VaultUrl({
            key: new Key("productReviewImages", asset.id),
        });
    }),
    brandFavicon: vaultFileUrlFactory,
    openGraphImage: vaultFileUrlFactory,
    font(asset) {
        return new URL(`https://${getDomain("transformless")}/font/${asset.id}`);
    },
    entityContent: composeImageTransformations((asset) => {
        return new VaultUrl({
            key: new Key("entityContent", asset.id),
            bucket: "tech.holibob.vault",
        });
    }),
    translationImport: undefined,
    bookingQuestion(asset) {
        if (asset.options?.format === "pdf") {
            return new URL(`https://${getDomain("transformless")}/bookingQuestion/${asset.id}`);
        }
        return new VaultUrl({
            key: new Key("bookingQuestion", asset.id),
            bucket: "tech.holibob.vault",
        });
    },
    entityAttachment: undefined,
    productAttachment(asset) {
        return new URL(`https://${getDomain("transformless")}/productAttachment/${asset.id}`);
    },
    systemTagIcon(asset) {
        return new URL(`https://${getDomain("transformless")}/systemTagIcon/${asset.id}`);
    },
    staticImage(asset) {
        return new VaultUrl({
            key: new Key("static/image", asset.id),
            bucket: "tech.holibob.vault",
        });
    },
    report: undefined,
    productSource: undefined,
};

function composeImageTransformations(urlFactory: AssetToVaultUrlFactory): AssetToUrlFactory {
    return (asset) => {
        if (asset.options?.format === "svg") {
            return new URL(`https://${getDomain("transformless")}/svg/${asset.id}`);
        }
        const vaultUrl = urlFactory(asset);
        if (!asset.options) {
            return vaultUrl;
        }
        return applyTransformationOptionsToUrl(vaultUrl, asset.options);
    };
}

function applyTransformationOptionsToUrl(url: VaultUrl, options: AssetOptions) {
    let edits: Partial<Edits> = url.edits ?? {};
    if (
        options.extractTop !== undefined &&
        options.extractLeft !== undefined &&
        options.extractWidth !== undefined &&
        options.extractHeight !== undefined
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { extract, ...oldEdits } = edits;
        edits = {
            ...oldEdits,
            extract: {
                top: options.extractTop,
                left: options.extractLeft,
                width: options.extractWidth,
                height: options.extractHeight,
            },
        };
    }

    if (options.resizePosition !== undefined) {
        edits = {
            ...edits,
            resize: {
                ...edits.resize,
                position: options.resizePosition,
            },
        };
    }

    if (Object.keys(edits).length > 0) {
        return url.modifyEdits(edits);
    }
    return url;
}

export function assetUrlToVaultUrl(assetUrl: AssetUrl) {
    const urlProducer = URL_PRODUCER[assetUrl.type];
    if (urlProducer) {
        return urlProducer(assetUrl);
    }

    throw new Error("There is no way to produce URL for given asset type");
}

export function assetUrlStringToVaultUrl(assetUrlString: string) {
    return AssetUrl.fromString(assetUrlString).map(assetUrlToVaultUrl);
}
